$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(window).on('load resize scroll', function() {
    $('#start').each(function() {
        if ($(this).isInViewport()) {
            //console.log ("visible");
            $(".goup").css("display", "none");
        } else {
            //console.log ("not visible");
            $(".goup").css("display", "");
        }
    });
});


$(function(){
    //console.log ("Started!");
    $("#strato-check-readme").load("/strato-check-readme.html");
    $("#strato-check-changelog").load("/strato-check-changelog.html");
});
